<template>
    <div class="login">
        <div class="login-head">东营区智慧公厕综合监管平台</div>
        <div class="login-body">
            <div class="login-box c-center">
                <div class="box">
                    <div class="title">用户登录</div>
                    <el-form @submit.native.prevent :model="account" :rules="rules" ref="login" @keyup.enter.native="validation">
                        <div v-if="loginType" class="login-user">
                            <el-form-item prop="username">
                                <el-input
                                    prefix-icon="el-icon-user"
                                    placeholder="请输入账号"
                                    v-model="account.username"
                                    name="username"
                                    type="text"
                                    autocomplete="off"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input
                                    type="password"
                                    prefix-icon="el-icon-lock"
                                    placeholder="请输入密码"
                                    v-model="account.password"
                                    name="password"
                                     autocomplete="off"
                                     show-password
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div v-else class="login-phone">
                            <el-form-item prop="phone">
                                <el-input
                                    prefix-icon="el-icon-phone"
                                    placeholder="请输入手机号"
                                    v-model="account.phone"
                                     autocomplete="off"
                                    name="phone"
                                ></el-input>
                            </el-form-item>
                            <el-form-item class="login-code" prop="code">
                                <el-input
                                    prefix-icon="el-icon-lock"
                                    placeholder="请输入验证码"
                                    v-model="account.code"
                                    name="code"
                                    autocomplete="off"
                                ></el-input>
                                <el-button  :type="isCode ? 'info' : 'primary'" @click="getCode" :disabled="isCode">{{
                                    codeTip
                                }}</el-button>
                            </el-form-item>
                        </div>
                        <el-form-item class="login-btn">
                            <el-button native-type="submit" style="width: 100%;" type="primary" @click="validation">登录</el-button>
                        </el-form-item>
                        <div class="login-option">
                            <span class="sw-login-btn" @click="changeLoginType">{{
                                loginType ? "短信登录" : "密码登录"
                            }}</span>
                            <el-checkbox v-model="loginState" @change="statusLog">7天内记住状态登录</el-checkbox>
                        </div>
                    </el-form>
                    <div class="login-footer">
                        <img src="../assets/img/zhifa.png" alt="">
                        <div class="zhifa">东营区综合行政执法局</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="code">
            <div class="code_box">
                <span class="code_title">移动端下载</span>
                <div class="code_img">
                    <!-- <div class="qrcode" id="qrcode" ref="qrcode"/> -->
                    <img src="../assets/img/QRcode.png" alt="">
                </div>
            </div>
        </div>
        <div class="login-foot">
            <span>系统开发：东营市旭瑞智能科技股份有限公司</span>
            <span style="margin-left: 50px;">技术支持：0546-8778677</span>
        </div>
    </div>
</template>

<script>
import axios from "../request";

export default {
    name: "Login",
    data() {
        return {
            account: {
                username: localStorage.getItem('phone') ||  localStorage.getItem('username')  ,
                // username: localStorage.getItem('username'),
                password: localStorage.getItem('password'),
                phone: localStorage.getItem('phone'),
                code: '',
            },
            rules: {
                username: {
                    required: true,
                    message: "请输入账号",
                    trigger: "blur",
                },
                password: {
                    required: true,
                    message: "请输入密码",
                    trigger: "blur",
                },
                phone: {
                    required: true,
                    pattern: /^1[0-9]{10}$/,
                    message: "请输入正确的手机号",
                    trigger: "blur",
                },
                code: {
                    required: true,
                    message: "请输入验证码",
                    trigger: "blur",
                },
            },
            loginState: Boolean(localStorage.getItem('loginState')),
            loginType: false,
            codeTip: "获取验证码",
            isCode: false,
        };
    },
    created() {
       


    },
    mounted() {
        this.loginType = Boolean(localStorage.getItem('loginType'))


      console.log('--------cg系统跳转--------')
      let afterUrl = window.location.hash
      // console.log(afterUrl)
      let urls = afterUrl.split('?')
      if (urls.length > 0) {
        let names = urls[1].split('&')
        if (names.length > 0) {
          let userName = names[0].split('=')[1]
          let password = names[1].split('=')[1]
          password = password.replace("%40", "@");
          this.loginType = true;
          this.account.username = userName;
          this.account.password = password;
          this.login();
          console.log('--------跳转登录--------')
        }
      }


    },
    beforeRouteEnter(to, form, next) {
        const session = sessionStorage.getItem("token") ?? localStorage.getItem("token");
        session ? next("/") : next();
        this.loginState=Boolean(localStorage.getItem('loginState'))
        console.log(localStorage.getItem('loginState'));
    },
    methods: {
        statusLog(val){
            console.log(val);
            if(!this.loginState){
                //getItem
                localStorage.removeItem("token")
                localStorage.removeItem("id")
                localStorage.removeItem("name")
                localStorage.removeItem("username")
                localStorage.removeItem("password")
                localStorage.removeItem("phone")
                localStorage.removeItem("loginState")
            }
        },
        validation() {
            this.$refs.login.validate(valid => {
                console.log(valid)
                valid && this.login();
            });
        },
        changeLoginType() {
           
            this.$refs.login.resetFields();
            this.loginType = !this.loginType;
            this.account.username = localStorage.getItem('phone') ||  localStorage.getItem('username')
        },
        async login() {
            let para = {};
            if (this.loginType) {
                // 账号密码登录
                para.userName = this.account.username;
                para.password = this.account.password;
                localStorage.setItem("loginType", true)
                
            } else {
                //手机登录
                para.userName = this.account.phone;
                para.password = this.account.code;
                localStorage.setItem("loginType", false)
            }
            let data = await axios.post("/login", para);
            console.log(data)
            if (data) {
                console.log(data);
                if(this.loginState){
                    //getItem
                    localStorage.setItem("token", data.token)
                    localStorage.setItem("id", data.id)
                    localStorage.setItem("name", data.roleName)
                    localStorage.setItem("username", data.userName)
                    localStorage.setItem("password", this.account.password)
                    localStorage.setItem("phone", data.phone)
                    localStorage.setItem("loginState", this.loginState)
                }else{
                    sessionStorage.setItem("token", data.token)
                    sessionStorage.setItem("id", data.id)
                    sessionStorage.setItem("name", data.roleName)
                    sessionStorage.setItem("username", data.userName)
                    localStorage.removeItem("token")
                    localStorage.removeItem("id")
                    localStorage.removeItem("name")
                    localStorage.removeItem("username")
                    localStorage.removeItem("password")
                    localStorage.removeItem("phone")
                    localStorage.removeItem("loginState")
                }
                this.$router.replace("/");
            }
        },
        async getCode() {
            if (/^1[0-9]{10}$/.test(this.account.phone)) {
                let data = await axios.post("/login/sendMessage", { userName: this.account.phone });
                if (data) {
                    this.$message({
                        showClose: true,
                        message: "验证码已发送",
                        type: 'success'
                    });
                    this.isCode = true;
                    let time = 60;
                    this.codeTip = `等待${time}秒`;
                    let timer = setInterval(() => {
                        time--;
                        if (time > 0) {
                            this.codeTip = `等待${time}秒`;
                        } else {
                            this.isCode = false;
                            this.codeTip = `获取验证码`;
                            clearInterval(timer);
                        }
                    }, 1000);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.login {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .login-head {
        text-align: center;
        text-shadow: 0px 5px 0px rgba(72, 136, 255, 0.25);
        font-size: 2.5rem;
        font-weight: bold;
        color: #3b7fff;
        padding: 20px 0;
    }
    .login-body {
        background: url("../assets/img/beij.png") center/cover no-repeat;
        flex: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .login-box {
        flex: none;
        margin-right: 10vw;
        background: rgba(255, 255, 255, 0.35);
        padding: 20px;
        box-shadow: 0px 3px 8px 0px rgba(30, 72, 111, 0.35);
        border-radius: 10px;
        .box {
            border-radius: 10px;
            background: white;
            padding: 30px 50px;
            width: 270px;
        }
        .title {
            color: $blue;
            font-size: 1.2rem;
            text-align: center;
            font-weight: bold;
            padding: 15px 0;
        }
    }
    .login-foot {
        color: #333333;
        text-align: center;
        font-weight: bold;
        padding: 40px 0;
    }
}
.login-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sw-login-btn {
        color: $blue;
        cursor: pointer;
        font-size: 0.9rem;
    }
}
.code{
    position: absolute;
    top: 30%;
    right: 0;
    width: 20px;
    padding: 10px 5px 10px 12px;
    line-height: 18px;
    text-align: center;
    background: #FFFFFF;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    &:hover{
        cursor: pointer;
        & > .code_box > .code_img{
            opacity: 1;
            right: 36px;
            transition: right 0.5s;
        }
    }
    .code_box{
        position: relative;
        .code_title{
            color: #0084ff;
        }
        .code_img{
            position: absolute;
            top: -9px;
            right: 0px;
            opacity: 0;
            padding: 5px;
            background: #FFFFFF;
            padding: 5px;
            .qrcode{
                width: 150px;
                height: 150px;
            }
        }
    }

}
.login-footer {
    display: flex;
    align-items: center;
    padding-top: 20px;
    line-height: 10px;
    .zhifa {
       color: #3B7FFF; 
       
       padding-left: 20px;
    }
}

.login-code ::v-deep .el-form-item__content {
    display: flex;
}

.login-code ::v-deep .el-button {
    margin-left: 15px;
}

.login-btn ::v-deep .el-button {
    background: #3b7fff;
    box-shadow: 0px 3px 15px 0px rgba(67, 121, 255, 0.2);
    border-radius: 4px;
}
.el-button--primary{
    background-color:#3b7fff
}
</style>
